<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",

  data: () => ({
    innerHeight: window.innerHeight,
  }),

  mounted() {
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.innerHeight = window.innerHeight;

      let root = document.querySelector(':root');
      root.style.setProperty('--inner-height', window.innerHeight + 'px');
    },
  },
};
</script>
