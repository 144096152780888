import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/Index4.vue')
    },
    {
        path: '/aanmelden',
        name: 'signup',
        component: () => import('@/views/Index3.vue')
    },
    {
        path: '/dresscode',
        name: 'dresscode',
        component: () => import('@/views/Index3.vue')
    },
    {
        path: '/dresscode/:category/:photo_id',
        name: 'dresscodephoto',
        component: () => import('@/views/Index3.vue')
    },
    {
        path: '/v1',
        name: 'index1',
        component: () => import('@/views/Index1.vue')
    },
    {
        path: '/v2',
        name: 'index2',
        component: () => import('@/views/Index2.vue')
    },
    {
        path: '/v3',
        name: 'index3',
        component: () => import('@/views/Index3.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/Admin.vue')
    }
]

export default new VueRouter({
    mode: 'history',
    routes
});
